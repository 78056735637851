import React, { useState } from "react"
import { VideoPlayer } from "./videoplayer"
import ReactGA from 'react-ga';

const VideoComponent = ({ videos, room, handle }) => {
  const [videoList, setVideoList] = useState(videos)

  const handleVideoChange = (id, newVid) => {
    
    let path = "";
    if (newVid === 4) { path = "/B35T/menu" }
    if (newVid === 6) { path = "/B35T/animation" }
    if (newVid === 7) { path = "/E8LAS/menu" }
    if (newVid === 8) { path = "/E8LAS/animation" }    
    if (newVid === 9) { path = "/B649/menu" }
    if (newVid === 10) { path = "/B649/animation" }    
    if (newVid === 11) { path = "/Sealix/menu" }
    if (newVid === 12) { path = "/Sealix/animation" }    
    if (newVid === 13) { path = "/Company-presentation/menu" }
    if (newVid === 14) { path = "/Brochures/animation" }
                  

    ReactGA.pageview(window.location.pathname + path);    

    let fadeTimer = 0;
    if (id ==3 || id == 16) {
      const fadeList = videoList.map(video => {
        if (video.node.strapiId === id) {
          const updatedVideo = {
            ...video,
            node: {
              ...video.node,
              fadeOut: true
            },
          }
          return updatedVideo
        }
        return video
      })
      setVideoList(fadeList)
      fadeTimer = 1000;
    }    

    const tempList = videoList.map(video => {
      if (video.node.strapiId === id) {
        const updatedVideo = {
          ...video,
          node: {
            ...video.node,
            isShowing: !video.node.isShowing,
            fadeOut: false
          },
        }
        return updatedVideo
      }
      return video
    })

    setTimeout(() => {
      const newList = tempList.map(video => {
        if (video.node.strapiId === newVid) {
          const updatedVideo = {
            ...video,
            node: {
              ...video.node,
              isShowing: true,
            },
          }
  
          return updatedVideo
        }
        return video
      })
      setVideoList(newList)
    }, fadeTimer);    


  }

  return (
      videoList.map((video) => {
        // console.log(video.node.strapiId + " " + video.node.isHeatRecovery)
        if(room == "heatrecovery"){
          if(video.node.isHeatRecovery == true){
            return (
              <VideoPlayer
                key={video.strapiId}
                video={video.node}
                onVideoChange={handleVideoChange}
              />
            )
          } 
        }
        if(room == "districtenergy"){
          if(video.node.isHeatRecovery != true){
            return (
              <VideoPlayer
                key={video.strapiId}
                video={video.node}
                onVideoChange={handleVideoChange}
              />
            )
          }
        }
      })
  )
}

export default VideoComponent
