import React from "react"
import MenuButton from "../MenuButton/menubutton"
import "./menu.scss"

const Menu = ({ btn, video, onVideoChange }) => {

/*
6 = B35T
7 = E8LAS
9 = B649
11 = Sealix 
*/
//console.log(video);
  /* HARD-CODED H1 and P */    
  let cssClass = "menu";
  let menuId = "menu-" + video.strapiId;
  if ((video.strapiId === 6) || (video.strapiId === 8) || (video.strapiId === 10) || (video.strapiId === 12)) {
      cssClass = "menu menu-animation";
  }

    
   let title = "";
   let lang = "en";
   let description = "";
   let brochureUrl = "";
   let productSheetUrl = "";

   description = video.description;

   if (typeof window !== 'undefined') {   
    if (window.location.href.toLowerCase().indexOf("lang=cn") > 0) { description = video.CN_description; lang = "cn"; };
    if (window.location.href.toLowerCase().indexOf("lang=de") > 0) { description = video.DE_description; lang = "de"; };
    if (window.location.href.toLowerCase().indexOf("lang=fr") > 0) { description = video.FR_description; lang = "fr"; };
    if (window.location.href.toLowerCase().indexOf("lang=pl") > 0) { description = video.PL_description; lang = "pl"; };
   }
  
    /*
    //B35T
    if ((video.strapiId === 4) || (video.strapiId === 6)) {
        title = "B35T";
        description = "The SWEP B35T can be utilized in what is known as a ‘2-stage’ execution. The 2-stage system is commonly used in substations within a Heat Network to reduce the returned temperature. Returned water from the radiator circuit heat exchanger is used to preheat the tap water. This significantly increases energy efficiency and thereby reduces costs.";
        brochureUrl = "/brochures/2-stage.pdf";
        productSheetUrl = "/productsheets/B35T.pdf";
    }

    //E8LAS
    if ((video.strapiId === 7) || (video.strapiId === 8)) {
        title = "E8LAS";
        description = "SWEP’s E8LAS is able to meet the highest level thermal demands for heating and tap water production, especially in a HIU (Heat Interface Unit). Its unique construction, with integrated start and end plates, combined with its compact design and impressive efficiency provides a highly versatile solution to many customer needs. ";
        brochureUrl = "/brochures/District Energy applications_EN.pdf";
        productSheetUrl = "/productsheets/E8LAS.pdf";
    }

    //B649
    if ((video.strapiId === 9) || (video.strapiId === 10)) {
        title = "B649";
        description = "The imposing B649 has been developed to handle capacities of up to 10 MW in Heat Networks and operates with a close temperature approach at high operating pressures, up to 25 bar. In District Heating networks, the B649 can be used as a pressure breaker between the Energy Center or plantroom and the residential and industrial buildings. In a District Cooling network, the B649 is typically used in substations, to transport cool water from the district cooling plant to urban buildings.";
        brochureUrl = "/brochures/DES_conversion_en.pdf";
        productSheetUrl = "/productsheets/B649.pdf";
    }

    //SEALIX
    if ((video.strapiId === 11) || (video.strapiId === 12)) {
        title = "Sealix";
        description = "This new range of brazed plate heat exchangers that keep tap water applications running efficiently for longer. It is achieved with SWEP Sealix® coating, a revolutionary SiO₂-based thin-film technology that increases the unit corrosion resistance and decreases the tendency for fouling and scaling. Sealix® surface protection reduces maintenance costs and improves system efficiency related to breakdowns and unplanned downtime.";
        brochureUrl = "/brochures/Sealix brochure_EN.pdf";
    }     
    */

    let menuButtons = "true";
    if (video.showMenuButtons != true)
        menuButtons = "false";

  return (video.showMenu === true) ? (
    <div className={cssClass} id={menuId} data-buttons={menuButtons}>
        <div className="menu-background"></div>
        <div className="menu-buttons">
    
            <ul>
                { video.title !== null ? 
                <li className="title">
                    <div className="menu-title">
                    <div className="menu-title__inner">
                            <h1>{video.title}</h1>
                            <p>
                                {description}
                            </p>
                        </div>
                    </div>
                </li>
                : ""
                }
                
                { btn.map((button) => {
                    return (
                    <MenuButton
                        btn={button}
                        video={video}
                        onVideoChange={onVideoChange}
                    />
                    )
                }) }

                <li>
                    <div class="menu-countdown">
                        <div id="countdown-number"></div>
                        <svg class="countdown-svg">
                            <circle r="18" cx="20" cy="20"></circle>
                        </svg>
                    </div> 
                </li>
            </ul>
            
        </div>        
    </div>
  ) : ""
}

export default Menu
