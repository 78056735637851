import React, { useRef, useEffect, useState } from "react"
import videojs from "video.js"
import abLoopPlugin from "videojs-abloop"
import Button from "./Button/button"
import Menu from "./Menu/menu"

if (typeof window !== `undefined`) {
  abLoopPlugin(window, videojs)
}

export const VideoPlayer = ({ video, onVideoChange }) => {
  const videoPlayerRef = useRef(null) // Instead of ID
  const [videoHasLoaded, setVideoHasLoaded] = useState(false);

  let videoSrc = video.url;
  if (typeof window !== 'undefined') {
    if (window.location.href.toLowerCase().indexOf("lang=cn") > 0) { videoSrc = video.CN_url };
    if (window.location.href.toLowerCase().indexOf("lang=de") > 0) { videoSrc = video.DE_url };
    if (window.location.href.toLowerCase().indexOf("lang=fr") > 0) { videoSrc = video.FR_url };
    if (window.location.href.toLowerCase().indexOf("lang=pl") > 0) { videoSrc = video.PL_url };
  }

  const videoJSOptions = {
    autoplay: false,
    controls: true,
    preload: true,
    controlBar: {
      fullscreenToggle: false,
    },
    sources: [
      {
        src: videoSrc,
        type: "video/mp4",
      },
    ],
    plugins: {
      abLoopPlugin: {
        enabled: true,
        loopIfBeforeStart: true,
        loopIfAfterEnd: true,
        pauseBeforeLooping: false,
        pauseAfterLooping: false,
      },
    },
  }

  const initVideo = () => {
    const player = videojs(videoPlayerRef.current, videoJSOptions, () => {
      player.src(videoJSOptions.sources.src)

      // Disable fullscreen mode on player on double click
      player.on("ready", () => {
        player.tech_.off("dblclick")
        setVideoHasLoaded(true);
        // sätta variablel med useState typ movieLoaded = true

        if (video.isShowing) {
          //player.play()
        } 
      })

      if (video.isShowing) {
        
        if(video.showMenuButtons === true) {
          let menu = document.getElementById("menu-" + video.strapiId);
          menu.classList.add('show');       
          menu.classList.remove('pulse');
          
          let viewHeight = menu.clientHeight;
          let screenWidth = 300;
          if (typeof window !== `undefined`) {
            screenWidth = window.innerWidth;
          }
          
          let videoWidth = (viewHeight * (16 / 9));
          let videoLeft = (screenWidth - videoWidth) / 2;

          menu.style.left = videoLeft + "px";
        }

        player.currentTime(0);

        if (window.location.href.indexOf('start') > 0) {
          if (video.strapiId == 2 || video.strapiId == 15) {
            if(video.strapiId == 15){
              onVideoChange(video.strapiId, 16)
            }
            else {
              onVideoChange(video.strapiId, 3)
            }
          }
        }

        player.play();
      }  else {
        player.currentTime(0);
        player.pause();
      }

      player.on("timeupdate", () => {
        if (!player.paused()) {
          var totalTime = player.duration();
          var currentTime = player.currentTime();
          var percentage = currentTime / totalTime;

          var counter = document.querySelector('.active .countdown-svg');
          if (counter != null) {
            counter.style.strokeDashoffset = (113 - (113 * percentage)) + "px";
          }
        }
      })

      if(video.strapiId == 2)
      {
        video.Button = [];
        let btn = {};
        btn.buttonIcon = null;
        btn.id = 99;
        btn.redirectTo = 3;
        btn.redirectUrl = null;
        btn.text = "SKIP INTRO";
        btn.type = "square-intro"
        btn.left = "100%";
        btn.top = "50%";

        video.Button.push(btn);
        // video.Button.id = 99;
        // onVideoChange(video.strapiId, 16)
      }

      if(video.strapiId == 15)
      {
        video.Button = [];
        let btn = {};
        btn.buttonIcon = null;
        btn.id = 99;
        btn.redirectTo = 16;
        btn.redirectUrl = null;
        btn.text = "SKIP INTRO";
        btn.type = "square-intro"
        btn.left = "100%";
        btn.top = "50%";

        video.Button.push(btn);
        // video.Button.id = 99;
        // onVideoChange(video.strapiId, 16)
      }
      if(video.strapiId == 16){
        console.log("video id: 16");
        console.log(video.Button)
      }

      player.on("ended", () => {
        if (video.loop) { 
          player.abLoopPlugin.setStart(0).playLoop() 
        }

        if (video.strapiId === 4 || video.strapiId === 7 || video.strapiId === 9 || video.strapiId === 11 || video.strapiId === 17 || video.strapiId === 19 || video.strapiId === 18 || video.strapiId === 26 || video.strapiId === 20 ) {
          let menu = document.getElementById("menu-" + video.strapiId);
          menu.classList.add('pulse');
        }
 
        if (video.strapiId === 2 || video.strapiId === 15) {
          if(video.strapiId === 15){
            onVideoChange(video.strapiId, 16)
          }
          else {
           onVideoChange(video.strapiId, 3)
          }
        }

        if (video.strapiId === 13) {
          onVideoChange(video.strapiId, 3)
        }        

        if ((video.strapiId === 6) || (video.strapiId === 8) || (video.strapiId === 10) || (video.strapiId === 12)) {
          onVideoChange(video.strapiId, 3)
        }
      })
    })
  }

  useEffect(() => {
    if (videoPlayerRef) {
      initVideo()
    }
  })

  return (
    <div id={video.strapiId} className={ video.isShowing ? "active" : "" } data-state={ video.fadeOut ? "fadeout" : "" }  style={{ display: video.isShowing ? "block" : "none" }}>
        <Menu
          btn={video.Button}
          video={video}
          onVideoChange={onVideoChange}
        />
        
        <video ref={videoPlayerRef} className="video-js" paused="true">
          <track kind="captions"></track>
        </video>

      {videoHasLoaded && video.Button && (video.strapiId === 3 || video.strapiId === 14 || video.strapiId === 16 || video.strapiId === 21 || video.strapiId === 2 || video.strapiId === 15) &&
        video.Button.map((btn) => {
          return (
            <Button
              key={btn.id}
              btn={btn}
              video={video} 
              onVideoChange={onVideoChange}
            />
          )
        })}

    </div>
  )
}
