import React from "react"
import "./button.scss"
import RoundBtn from "../../assets/svg/btn-round.svg"
import Icon from "../../assets/svg/back.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../fontawesome';

const Button = ({ btn, video, onVideoChange }) => {

  let viewHeight = 300 - 74;
  let screenWidth = 500;
  let voidToTop = 0;
  let videoHeight = 0;
  let videoWidth = 0;
  let voidToLeft = 0;
  let screenHeight = 0;


  if (typeof window !== `undefined`) {
    viewHeight = window.innerHeight - 74;
    screenWidth = window.innerWidth;
    screenHeight = window.innerHeight;
    
    videoHeight = screenWidth / (16 / 9);
    voidToTop = (viewHeight - videoHeight) / 2;
    
    videoWidth = screenHeight * (16 / 9);
    voidToLeft = (screenWidth - videoWidth) / 2;
  }


  //debugger
  //let videoWidth = (viewHeight * (16 / 9));
  let videoLeft = (screenWidth - videoWidth) / 2;
  let buttonLeft = (btn.left !== null) ? ((btn.left.replace("%", "") / 100) * videoWidth) + voidToLeft : 0;

  let buttonTop = (btn.top !== null) ? ((btn.top.replace("%", "") / 100) * videoHeight) + voidToTop : 0;

  let btnText = btn.text;
  if (typeof window !== 'undefined') {
    if (window.location.href.toLowerCase().indexOf("lang=cn") > 0) { btnText = btn.CN_text };
    if (window.location.href.toLowerCase().indexOf("lang=de") > 0) { btnText = btn.DE_text };
    if (window.location.href.toLowerCase().indexOf("lang=fr") > 0) { btnText = btn.FR_text };
    if (window.location.href.toLowerCase().indexOf("lang=pl") > 0) { btnText = btn.PL_text };
  }

  // if(btn.type === "skip-intro"){
  //   return (
  //     <RoundBtn className="btn.type"
  //     id={btn.id}
  //     onClick={() => onVideoChange(video.strapiId, btn.redirectTo)}
  //     />
  //   )
  // }

  if(btn.id == 99){
    return (
      <button class="skip-intro" style={{bottom:'10%', right:'15%', position:'absolute', background:'unset', fontSize:'30px', color: '#d3d1d1', padding: '15px', border: '1px solid #d3d1d1', borderRadius: '10px'}} onClick={() => onVideoChange(video.strapiId, btn.redirectTo)}>
        SKIP INTRO
      </button>
    )

  }
 

  return btn.type === "round" ? (  
    <div className="btn" data-key={btn.id} key={btn.id} style={{ left: buttonLeft, top: buttonTop, position: "absolute" }} data-left={btn.left} data-top={btn.top}>
      <RoundBtn
        className={btn.type}
        id={btn.id}
        onClick={() => onVideoChange(video.strapiId, btn.redirectTo)}
      />
      <p id="button-round-text">
        {btnText}
      </p>
    </div>
  ) : (
    <div className="btn" key={btn.id} data-left={btn.left} data-top={btn.top} data-type={btn.type}>

      { btn.type === "transparent" ? 
        <a className="transparent" style={{ left: buttonLeft, top: buttonTop }}  href={btn.redirectUrl} target="_blank">
          <p>{btnText}</p>
        </a>
        :
        ""
      }
      <button
        className={btn.type}
        id={btn.id}
        style={{ left: buttonLeft, top: buttonTop }}
        onClick={() => onVideoChange(video.strapiId, btn.redirectTo)}
      />
      {btn.type === "icon" ? <Icon className="icon" style={{ left: btn.left, top: btn.top }} onClick={() => onVideoChange(video.strapiId, btn.redirectTo)}
      /> 
      : <p id="button-square-text" style={{ left: btn.left, top: btn.top }}>
        {btnText}
      </p>}
    
    </div>
  )
}

export default Button
