import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../fontawesome';

const MenuButton = ({ btn, video, onVideoChange }) => {
    let icon = btn.buttonIcon;

    let btnText = btn.text;
    let redirectUrl = btn.redirectUrl;
    if (typeof window !== 'undefined') {    
        if (window.location.href.toLowerCase().indexOf("lang=cn") > 0) { 
            btnText = btn.CN_text;
            redirectUrl = btn.CN_redirectUrl;
        };
        if (window.location.href.toLowerCase().indexOf("lang=de") > 0) { 
            btnText = btn.DE_text;
            redirectUrl = btn.DE_redirectUrl;
        };
        if (window.location.href.toLowerCase().indexOf("lang=fr") > 0) { 
            btnText = btn.FR_text;
            redirectUrl = btn.FR_redirectUrl;
        };
        if (window.location.href.toLowerCase().indexOf("lang=pl") > 0) { 
            btnText = btn.PL_text;
            redirectUrl = btn.PL_redirectUrl; 
        };
    }

    if (icon !== null)
        icon = icon.replace(/_/g, '-');

    return btn.type === "link" ? (
        <li>
            <a 
                className="menu-icon"
                href={btn.redirectUrl}
                target="_blank"
                without rel="noreferrer"
            >
                <div className="menu-icon-fa">
                    <FontAwesomeIcon icon={['fa', icon]} />
                </div>
            </a>
            <a
                className = "menu-button menu-button-3"
                href={redirectUrl}
                target="_blank"
                without rel="noreferrer"
            > { btnText } </a>  
        </li>
    ) : (
        <li data-type={btn.type}>
            <button 
                className="menu-icon"
                onClick={() => onVideoChange(video.strapiId, btn.redirectTo)}
            >
                <div className="menu-icon-fa">
                    <FontAwesomeIcon icon={['fa', icon]} />
                </div>
            </button>
            <button
                className = "menu-button menu-button-1"
                id = "animate"
                onClick={() => onVideoChange(video.strapiId, btn.redirectTo)}
                data-icon={icon}
            > { btnText } </button>    
        </li>
    )
}

export default MenuButton 